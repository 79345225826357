.butsdiv {
  padding: 30px 30px 30px 0;
}
.but1 {
  padding: 15px;
  border: 1px solid #ddd;
  text-transform: uppercase;
  font-size: large;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 1px 1px 10px #ddd;
}
.but1:hover {
  border: 1px solid #91d5ff;
  box-shadow: 1px 1px 10px #ddd;
  transition: 300ms;
}
.but1.online {
  background-color: #4285f6;
  color: white;
}
.but1.ofiste {
  background-color: #34a856;
  color: white;
}
.but1.evde {
  background-color: #ed4235;
  color: white;
}
h4.ant-list-item-meta-title {
  margin-bottom: 0 !important;
  color: #239947 !important;
  font-size: 14px !important;
}
.mesajlist.ant-list-item.ant-list-item-no-flex {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-color: #f5f5f5;
  font-size: 13px !important;
}
.messagelist .ant-list-header {
  border: 1px solid #ddd;
}
.messagelist .ant-list-footer {
  border: 1px solid #ddd;
}
.messagelist {
  margin-bottom: 30px !important;
}
.mdivider {
  margin-bottom: 1px !important;
  margin-top: 1px !important;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: 0px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 0px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}
.badgeribbon {
  margin-right: -10px;
}
.ant-menu-item {
  padding-left: 15px !important;
  margin-bottom: 0 !important;
}
