.banner-icon-container {
  text-align: center !important;
  width: 150px;
  height: 150px;
  padding-top: 38px;
  border-radius: 75px;
  border: 8px solid #ffffff;
  box-shadow: 1px 1px 10px lightgray;
  color: white;
}
.banner-icon {
  width: 64px !important;
  font-size: 64px;

  height: 64px !important;
}
.icon-col {
  padding-left: calc(50% - 75px) !important;
}
.banner-icon-container.bir {
  background-color: #dc3645 !important;
}
.banner-icon-container.iki {
  background-color: #ffcc15 !important;
}
.banner-icon-container.uc {
  background-color: #0070c9 !important;
}
.banner-icon-container.dort {
  background-color: #128395 !important;
}
.baslik {
  color: white !important;
  font-weight: 300;
}
.dersdetay {
  color: white !important;
  font-size: 16px;
}
.cont-cont {
  background-color: #37383a;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.contact-bg {
  background-image: url("../../assets/images/contact-bg-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.footer-liste > li {
  line-height: 30px !important;
}
.footer-liste > li:hover {
  font-weight: 700;
}

.footer-liste > li::before {
  content: "\279c \0020";
}
.footer-liste > li:hover:before {
  color: #fbbd05 !important;
}
.sosyal {
  font-size: 32px;
  color: lightgray;
}
.sosyal:hover {
  color: #fbbd05;
  cursor: pointer;
}
.isFavoriteCard {
  box-shadow: 0 0 10px #ccc !important;
  border: 1px solid #34a856 !important;
}
.isFavoriteCard .ant-card-head-title {
  color: #34a856 !important;
}
.isNormalCard {
  border: 1px solid #ddd;
}
.isNormalCard .ant-card-head-title {
  color: #1890ff !important;
}
.logo-footer {
  max-width: 250px;
  border: 5px solid black;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 10px black;
}
