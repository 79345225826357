.logo-staff {
  background-image: url("../../assets/images/logo.png");
  height: 50px;
  margin: 7px 10px;
  background-size: cover;
  background-position: center;
}

.site-layout-sub-header-background {
  background: #fff;
  height: 65px;
}
/* 
.ant-layout-sider-zero-width-trigger {
  background-color: #ebebeb !important;
  font-size: 26px;
  box-shadow: 1px 1px 15px lightgrey;
  border: 1px solid #ccc !important;
  top: 10px !important;
} */
.ant-layout {
  background-color: #fafafa;
}
.ant-layout-footer {
  border-top: 1px solid #ddd;
}
.ant-list-header {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #ddd !important;
}
.ant-list-footer {
  background-color: #f5f5f5 !important;
  border-top: 1px solid #ddd !important;
}
.ql-snow.ql-toolbar {
  display: block;
  background: #f5f5f5;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.ql-editor {
  background-color: white;
  height: 300px !important;
}
.ant-upload {
  background-color: #f5f5f5 !important;
}
.rate-area {
  background-color: #444;
  margin: 20px;
  padding: 5px 0 8px 0;
  border-radius: 5px;
  border: 3px solid white;
  box-shadow: 1px 1px 10px #666;
}
.crb {
  border-radius: 50% !important;
  border-color: #fdbb9d !important;
}
.crb.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: gray !important;
  color: lightgray !important;
}

.cadiv {
  border: 1px solid #fac9b3;
  padding: 10px;
  text-align: center;
  background-color: #ffebe2;
  border-radius: 5px;
  box-shadow: 1px 1px 10px lightgray;
}
.crb .ant-radio-button-checked {
  border-radius: 99px !important;

  background-color: #444 !important;
}
.abg {
  background-color: #fddbcc;
  padding: 2px 0 3px 0;
}
.bbg {
  background-color: #ffebe2;
  padding: 2px 0 3px 0;
}
.irb .ant-radio-button-checked {
  background-color: #dc3645 !important;
  color: white !important;
  z-index: -1;
}
.parent {
  position: relative;
  width: 100%;
  height: 100%;
  top: 150px;
  transform: translateY(-150px);
  box-sizing: border-box;
  overflow-x: scroll;
  overflow: scroll;
  overflow-y: scroll;
  /* float: left; */
  overflow-x: scroll;
  margin-bottom: 20px;
}
.child {
  width: 1200px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 15px;
  box-shadow: 1px 1px 10px lightgrey;
  height: 100%;
  box-sizing: border-box;
  /* float: none; */
  display: -webkit-inline-box;
  /* vertical-align: bottom; */
}
.child h2 {
  font-size: 13px;
  margin-bottom: -10px;
}
.child p {
  font-weight: 400;
}
.modaldiv div.ant-modal-body {
  background-color: #f5f5f5 !important;
  border: 1px solid #ddd;
}
.mainalert .ant-alert-icon {
  font-size: 36px !important;
  opacity: 0.5;
}
.mainalert {
  margin-bottom: 10px;
}
.reporttable th {
  color: #1890ff !important;
  background-color: #f5f5f5 !important;
  border-color: #ddd !important;
}
.teacher-stats {
  text-align: center !important;
}
.teacher-stats .ant-descriptions-item-label {
  font-weight: 500;
}
.trigger {
  font-size: 30px;
  line-height: 64px;
  padding: 3px 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: #1890ff;
}
.stats-bar {
  border: 1px solid #ddd;
  background-color: white;
  box-shadow: 0 0 5px #eee;
  padding: 10px 15px;
}
