@import url(https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@300;400;500;600&display=swap);
* {
  font-family: "Encode Sans Semi Condensed", sans-serif;
}

h3 {
  color: #555;
  font-weight: 400 !important;
}
.container {
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 1px 1px 15px #000;
  max-width: 95%;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.logo {
  height: 44px;
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.main-menu {
  text-align: center;
  border-bottom: 1px solid #ccc;
}
.main-menu .ant-menu-item {
  padding-right: 15px !important;
}
.logo-center {
  text-align: center;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
}
.flag {
  width: 32px;
}
.login-page2 {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/bg.71fd0856.jpg);
  background-repeat: repeat;
}

.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/bg.71fd0856.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.login-form {
  width: 320px;
  max-width: "95%";
}
.login-form-container {
  padding: 20px 20px 0 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fafafa;
  box-shadow: 1px 1px 10px #333;
}
.login-logo {
  width: 200px;
  margin-left: calc(100% / 2 - 100px);
}
.loader-bg {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #252427;
}
.loader-bg > img {
  margin-top: -100px;
  margin-left: -20px;
  position: absolute;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 60px;
  height: 60px;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
.dynamic-delete-button {
  margin: 0 8px;
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.banner-icon-container {
  text-align: center !important;
  width: 150px;
  height: 150px;
  padding-top: 38px;
  border-radius: 75px;
  border: 8px solid #ffffff;
  box-shadow: 1px 1px 10px lightgray;
  color: white;
}
.banner-icon {
  width: 64px !important;
  font-size: 64px;

  height: 64px !important;
}
.icon-col {
  padding-left: calc(50% - 75px) !important;
}
.banner-icon-container.bir {
  background-color: #dc3645 !important;
}
.banner-icon-container.iki {
  background-color: #ffcc15 !important;
}
.banner-icon-container.uc {
  background-color: #0070c9 !important;
}
.banner-icon-container.dort {
  background-color: #128395 !important;
}
.baslik {
  color: white !important;
  font-weight: 300;
}
.dersdetay {
  color: white !important;
  font-size: 16px;
}
.cont-cont {
  background-color: #37383a;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.contact-bg {
  background-image: url(/static/media/contact-bg-2.3241de93.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.footer-liste > li {
  line-height: 30px !important;
}
.footer-liste > li:hover {
  font-weight: 700;
}

.footer-liste > li::before {
  content: "\279c \0020";
}
.footer-liste > li:hover:before {
  color: #fbbd05 !important;
}
.sosyal {
  font-size: 32px;
  color: lightgray;
}
.sosyal:hover {
  color: #fbbd05;
  cursor: pointer;
}
.isFavoriteCard {
  box-shadow: 0 0 10px #ccc !important;
  border: 1px solid #34a856 !important;
}
.isFavoriteCard .ant-card-head-title {
  color: #34a856 !important;
}
.isNormalCard {
  border: 1px solid #ddd;
}
.isNormalCard .ant-card-head-title {
  color: #1890ff !important;
}
.logo-footer {
  max-width: 250px;
  border: 5px solid black;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 10px black;
}

.butsdiv {
  padding: 30px 30px 30px 0;
}
.but1 {
  padding: 15px;
  border: 1px solid #ddd;
  text-transform: uppercase;
  font-size: large;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 1px 1px 10px #ddd;
}
.but1:hover {
  border: 1px solid #91d5ff;
  box-shadow: 1px 1px 10px #ddd;
  transition: 300ms;
}
.but1.online {
  background-color: #4285f6;
  color: white;
}
.but1.ofiste {
  background-color: #34a856;
  color: white;
}
.but1.evde {
  background-color: #ed4235;
  color: white;
}
h4.ant-list-item-meta-title {
  margin-bottom: 0 !important;
  color: #239947 !important;
  font-size: 14px !important;
}
.mesajlist.ant-list-item.ant-list-item-no-flex {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-color: #f5f5f5;
  font-size: 13px !important;
}
.messagelist .ant-list-header {
  border: 1px solid #ddd;
}
.messagelist .ant-list-footer {
  border: 1px solid #ddd;
}
.messagelist {
  margin-bottom: 30px !important;
}
.mdivider {
  margin-bottom: 1px !important;
  margin-top: 1px !important;
}
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: 0px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 0px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}
.badgeribbon {
  margin-right: -10px;
}
.ant-menu-item {
  padding-left: 15px !important;
  margin-bottom: 0 !important;
}

.logo-staff {
  background-image: url(/static/media/logo.5285997a.png);
  height: 50px;
  margin: 7px 10px;
  background-size: cover;
  background-position: center;
}

.site-layout-sub-header-background {
  background: #fff;
  height: 65px;
}
/* 
.ant-layout-sider-zero-width-trigger {
  background-color: #ebebeb !important;
  font-size: 26px;
  box-shadow: 1px 1px 15px lightgrey;
  border: 1px solid #ccc !important;
  top: 10px !important;
} */
.ant-layout {
  background-color: #fafafa;
}
.ant-layout-footer {
  border-top: 1px solid #ddd;
}
.ant-list-header {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #ddd !important;
}
.ant-list-footer {
  background-color: #f5f5f5 !important;
  border-top: 1px solid #ddd !important;
}
.ql-snow.ql-toolbar {
  display: block;
  background: #f5f5f5;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.ql-editor {
  background-color: white;
  height: 300px !important;
}
.ant-upload {
  background-color: #f5f5f5 !important;
}
.rate-area {
  background-color: #444;
  margin: 20px;
  padding: 5px 0 8px 0;
  border-radius: 5px;
  border: 3px solid white;
  box-shadow: 1px 1px 10px #666;
}
.crb {
  border-radius: 50% !important;
  border-color: #fdbb9d !important;
}
.crb.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: gray !important;
  color: lightgray !important;
}

.cadiv {
  border: 1px solid #fac9b3;
  padding: 10px;
  text-align: center;
  background-color: #ffebe2;
  border-radius: 5px;
  box-shadow: 1px 1px 10px lightgray;
}
.crb .ant-radio-button-checked {
  border-radius: 99px !important;

  background-color: #444 !important;
}
.abg {
  background-color: #fddbcc;
  padding: 2px 0 3px 0;
}
.bbg {
  background-color: #ffebe2;
  padding: 2px 0 3px 0;
}
.irb .ant-radio-button-checked {
  background-color: #dc3645 !important;
  color: white !important;
  z-index: -1;
}
.parent {
  position: relative;
  width: 100%;
  height: 100%;
  top: 150px;
  transform: translateY(-150px);
  box-sizing: border-box;
  overflow-x: scroll;
  overflow: scroll;
  overflow-y: scroll;
  /* float: left; */
  overflow-x: scroll;
  margin-bottom: 20px;
}
.child {
  width: 1200px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 15px;
  box-shadow: 1px 1px 10px lightgrey;
  height: 100%;
  box-sizing: border-box;
  /* float: none; */
  display: -webkit-inline-box;
  /* vertical-align: bottom; */
}
.child h2 {
  font-size: 13px;
  margin-bottom: -10px;
}
.child p {
  font-weight: 400;
}
.modaldiv div.ant-modal-body {
  background-color: #f5f5f5 !important;
  border: 1px solid #ddd;
}
.mainalert .ant-alert-icon {
  font-size: 36px !important;
  opacity: 0.5;
}
.mainalert {
  margin-bottom: 10px;
}
.reporttable th {
  color: #1890ff !important;
  background-color: #f5f5f5 !important;
  border-color: #ddd !important;
}
.teacher-stats {
  text-align: center !important;
}
.teacher-stats .ant-descriptions-item-label {
  font-weight: 500;
}
.trigger {
  font-size: 30px;
  line-height: 64px;
  padding: 3px 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: #1890ff;
}
.stats-bar {
  border: 1px solid #ddd;
  background-color: white;
  box-shadow: 0 0 5px #eee;
  padding: 10px 15px;
}

